var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CBreadcrumb',{staticStyle:{"margin-top":"-28px"},attrs:{"items":_vm.links}}),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardBody',[_c('TheTableHeader',{attrs:{"header":'LOKASI DATA CENTER',"subHeader":'DAFTAR DATA CENTER MILIK LINTASARTA .',"buttonShow":"","buttonText":'Add New',"buttonUrl":'sites/form/0'}})],1)],1),_c('CCard',[_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","small":"","tableFilter":"","sorter":"","itemsPerPageSelect":"","items":_vm.sites,"fields":_vm.fields,"items-per-page":5,"pagination":"","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(_vm.sites.map(function(x) {return x.id; }).indexOf(item.id)+1))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.email || '...')),_c('br'),_c('small',[_vm._v("Phone: "),_c('strong',[_vm._v(_vm._s(item.phone || '...'))])])])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at))+" "+_vm._s(_vm._f("formatTime")(item.created_at)))])])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.editSite(item.id)}}},[_vm._v("Edit")]),_vm._v("   "),_c('CButton',{attrs:{"color":"danger","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.removeSite(item.id)}}},[_vm._v("Delete")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }