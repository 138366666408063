<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="12">

				<CCard>
    			<CCardBody>
						<TheTableHeader
							:header="'LOKASI DATA CENTER'"
							:subHeader="'DAFTAR DATA CENTER MILIK LINTASARTA .'"
							buttonShow
							:buttonText="'Add New'"
							:buttonUrl="'sites/form/0'"
						/>
					</CCardBody>
				</CCard>

				<CCard>
					<CCardBody>
						<CDataTable
							hover
							striped
							border
							small
							tableFilter
							sorter
							itemsPerPageSelect
							:items="sites"
							:fields="fields"
							:items-per-page="5"
							pagination
							:loading="isLoading"
						>
							<template #id="{item}">
								<td align="center">{{sites.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
							</template>
							<template #email="{item}">
								<td>{{item.email || '...'}}<br><small>Phone: <strong>{{item.phone || '...'}}</strong></small></td>
							</template>
							<template #created_at="{item}">
								<td align="center">
									<p>{{item.created_at | formatDate}}  {{item.created_at | formatTime}}</p>
								</td>
							</template>
							<template #action="{item}">
            		<td align="center">
              		<CButton color="primary" variant="outline" square size="sm" @click="editSite(item.id)">Edit</CButton> &nbsp; 
									<CButton color="danger" variant="outline" square size="sm" @click="removeSite(item.id)">Delete</CButton>
            		</td>
          		</template>
						</CDataTable>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>	
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'TableMasterSite',
	components: { TheTableHeader },
	data () {
		return {
			role: localStorage.getItem('role'),
			sites: [],
			fields: [
				{ key: 'id', label: 'No' }, 
				'site_name', 
				{ key: 'email', label: 'Support Helpdesk' }, 
				'address', 
				'created_at', 
				'action'
			],
			currentPage: 1,
			perPage: 5,
			totalRows: 1,
			isLoading: true,
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Data Center',
        }
      ],
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goCreate(){
      this.$router.push({ path: 'sites/form/0' });
    },
		getBadge(status) {
			return status === 1 ? 'success' : 'danger'
		},
		getSites() {
			let self = this;
			return http.get('/sites')
			.then(function (response) {
				self.sites = response.data.data;
				self.isLoading = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		editSite(id) {
      this.$router.push({ path: 'sites/form/' + id });
    },
		removeSite(id) {
			let self = this;
			if (confirm('Yakin Ingin Menghapus ?')) {
    		return http.delete('/sites/' + id)
					.then(function (response) {
						self.getSites();
						self.toast('Berhasil Menghapus Data Center Dari Daftar.', 'info');
						console.log(response);
					}).catch(function (error) {
						console.log(error);
					});
  		}
		}
	},
	mounted: function(){
		this.getSites();
	}
}

</script>
